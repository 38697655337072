<script setup lang="ts">
// TODO: Dedupe all the Home search refine files
// possible move all reactive script stuff to a composable
import type { PartialFormatter } from 'nouislider';
import History from '@base/assets/icons/history.svg?raw';

const { $cagGoogle } = useNuxtApp();
const appConfig = useAppConfig();
const dealersStore = useDealersStore();
const inventoryApi = new InventoryApi();

// Load all available cars
const { data: cars } = await useAsyncData('home-search-refine', () =>
    inventoryApi.getHomeRefineInventoryV2(
        appConfig.arkonas.fleet || appConfig.arkonas.site
    )
);

// Divide the cars into groups by type
const sortedCars = computed(() => {
    const sortedCars: Record<
        FrontendCarType,
        HomeSearchRefineWithVehiclePricing[]
    > = {
        new: [],
        used: [],
        cpo: [],
    };

    if (!cars.value) return sortedCars;

    cars.value.forEach((car) => {
        const vehiclePricing = useVehiclePricing({
            msrp: car.pricing.msrp,
            isVehicleNew: car.type === 'N',
            pageType: 'SRP',
            exclusiveDiscount: car.pricing.exclusiveDiscount,
            markupsTotal: car.pricing.markupsTotal,
            rebatesAppliedTotal: car.pricing.rebatesAppliedTotal,
            rebatesEveryoneTotal: car.pricing.rebatesEveryoneTotal,
            discountsTotal: car.pricing.discountsTotal,
        });

        if (car.type === 'N')
            sortedCars.new.push({ car, pricing: vehiclePricing });
        if (car.type === 'U')
            sortedCars.used.push({ car, pricing: vehiclePricing });
        if (car.cpo) sortedCars.cpo.push({ car, pricing: vehiclePricing });
    });
    return sortedCars;
});

// Determine the absolute min and max price for each type
const getPriceLimit = (
    group: HomeSearchRefineWithVehiclePricing[]
): [number, number] => {
    const finalPrices = group.map((item) => item.pricing.finalPriceValue.value);
    const min = Math.min(...finalPrices);
    const max = Math.max(...finalPrices);
    return [Math.floor(min / 5000) * 5000, Math.ceil(max / 5000) * 5000];
};

const priceLimits = reactive({
    new: getPriceLimit(sortedCars.value.new),
    used: getPriceLimit(sortedCars.value.used),
    cpo: getPriceLimit(sortedCars.value.cpo),
});

// For keeping track of the currently selected car
const selected = reactive({
    type: '' as FrontendCarType,
    make: '',
    model: '',
    trim: '',
    price: [0, 0] as [number, number],
});

if (sortedCars.value.new.length) {
    selected.type = 'new';
    selected.price = priceLimits.new;
} else {
    selected.type = 'used';
    selected.price = priceLimits.used;
}

// Only use types that have cars
const types = computed(() => {
    const types: {
        label: string;
        value: FrontendCarType;
    }[] = [];

    if (sortedCars.value.new.length) {
        types.push({ label: 'New', value: 'new' });
    }

    if (sortedCars.value.used.length) {
        types.push({
            label: appConfig.homeSearchRefineOptions.usedLabel,
            value: 'used',
        });
    }

    if (sortedCars.value.cpo.length) {
        types.push({ label: 'Certified', value: 'cpo' });
    }

    return types;
});

// Some sites only allow specific makes to show up as CPO
const filterCpo = (make: string) => {
    return (
        selected.type === 'cpo' &&
        appConfig.searchOptions.cpoFilterByMake &&
        !dealersStore.currentDealer.makes.includes(make)
    );
};

// All the cars after filtering
const filteredCars = computed(() => {
    if (!selected.type) return [];

    return sortedCars.value[selected.type].filter((item) => {
        if (filterCpo(item.car.make)) return false;
        if (selected.make && selected.make !== item.car.make) return false;
        if (selected.model && selected.model !== item.car.model) return false;
        if (selected.trim && selected.trim !== item.car.trim) return false;
        if (item.pricing.finalPriceValue.value < selected.price[0])
            return false;
        if (item.pricing.finalPriceValue.value > selected.price[1])
            return false;
        return true;
    });
});

// For the select dropdown menus of make model trims
const collator = new Intl.Collator('en', {
    sensitivity: 'base',
    ignorePunctuation: true,
});
const orderList = (list: string[]) => {
    return list.sort((a, b) => {
        if (a === null) return 1;
        if (b === null) return -1;

        return collator.compare(a, b);
    });
};
const makes = computed(() => {
    const filteredMakes = sortedCars.value[selected.type].filter((item) => {
        if (filterCpo(item.car.make)) return false;
        return true;
    });

    return orderList([...new Set(filteredMakes.map((item) => item.car.make))]);
});
const models = computed(() => {
    if (!selected.type) return [];

    const filteredModels = sortedCars.value[selected.type].filter((item) => {
        if (filterCpo(item.car.make)) return false;
        if (selected.make && selected.make !== item.car.make) return false;
        return true;
    });

    return orderList([
        ...new Set(filteredModels.map((item) => item.car.model)),
    ]);
});
const trims = computed(() => {
    if (!selected.type) return [];

    const filteredTrims = sortedCars.value[selected.type].filter((item) => {
        if (filterCpo(item.car.make)) return false;
        if (selected.make && selected.make !== item.car.make) return false;
        if (selected.model && selected.model !== item.car.model) return false;
        return true;
    });

    const trimsSet = new Set(filteredTrims.map((item) => item.car.trim));

    // Delete empty string, some cars don't have trims
    trimsSet.delete('');
    trimsSet.delete(' ');

    return orderList([...trimsSet]);
});

// Determine if the vue-slider component should render
const showSlider = computed(() => {
    // If upper or lower price limit is Infinity or -Infinity
    const infiniteCondition =
        !isFinite(priceLimits[selected.type][0]) ||
        !isFinite(priceLimits[selected.type][1]);

    // If upper and lower price limits are the same
    const zeroRangeCondition =
        priceLimits[selected.type][0] === priceLimits[selected.type][1];

    if (infiniteCondition || zeroRangeCondition) return false;
    else return true;
});

const tooltipFormatter: PartialFormatter = {
    to: (value) => `$${Math.round(value) / 1000}k`,
};

// Use the results to go to next page
const addFiltersToQuery = () => {
    const query: {
        [key: string]: string | number;
    } = {};

    const keys = Object.keys(selected);
    keys.forEach((key) => {
        if (key === 'make' || key === 'model' || key === 'trim') {
            const value = selected[key];
            if (value) query[key] = urlEncode(value);
        } else if (key === 'price') {
            const limits = priceLimits[selected.type];
            if (selected.price[0] !== limits[0]) {
                query.minPrice = selected.price[0];
            }
            if (selected.price[1] !== limits[1]) {
                query.maxPrice = selected.price[1];
            }
        }
    });
    return query;
};

const submit = () => {
    const query = addFiltersToQuery();
    $cagGoogle.pushToDataLayer({
        category: 'home search refine',
        type: selected.type,
    });

    if (appConfig.hub.isChoice) {
        navigateTo({ path: `/search/${selected.type}/cars`, query });
    } else if (appConfig.hub.location) {
        navigateTo({
            path: `/search/${selected.type}/cars/${appConfig.hub.location}`,
            query,
        });
    } else {
        navigateTo({ path: `/search/${selected.type}`, query });
    }
};

// Reset the filters whenever the type changes
const resetFilters = () => {
    selected.make = '';
    selected.model = '';
    selected.trim = '';
    selected.price = priceLimits[selected.type];
};

watch(
    () => selected.type,
    () => {
        resetFilters();
    }
);

watch(
    () => selected.make,
    () => {
        selected.model = '';
        selected.trim = '';
    }
);

watch(
    () => selected.model,
    () => {
        selected.trim = '';
    }
);
</script>

<template>
    <CenterContent padding="30px 2.5rem">
        <div class="home-search-refine">
            <div class="header-wrap">
                <HeadingText
                    :flavor="appConfig.homeSearchRefineOptions.flavor"
                    class="heading-text"
                >
                    Search
                </HeadingText>

                <FormsInputSegmentedControl
                    v-model="selected.type"
                    hide-error
                    hide-label
                    name="hsr-type"
                    :options="types"
                    :class="{
                        uppercase: appConfig.homeSearchRefineOptions.uppercase,
                    }"
                />
            </div>

            <div class="filters">
                <div class="select-wrap">
                    <select v-model="selected.make" aria-label="Select make">
                        <option value="">
                            {{ appConfig.theme === 'vw' ? 'Make' : 'MAKE' }}
                        </option>

                        <option v-for="make in makes" :key="make" :value="make">
                            {{ make }}
                        </option>
                    </select>

                    <select v-model="selected.model" aria-label="Select model">
                        <option value="">
                            {{ appConfig.theme === 'vw' ? 'Model' : 'MODEL' }}
                        </option>

                        <option
                            v-for="model in models"
                            :key="model"
                            :value="model"
                        >
                            {{ model }}
                        </option>
                    </select>

                    <select v-model="selected.trim" aria-label="Select trim">
                        <option value="">
                            {{ appConfig.theme === 'vw' ? 'Trim' : 'TRIM' }}
                        </option>

                        <option v-for="trim in trims" :key="trim" :value="trim">
                            {{ trim }}
                        </option>
                    </select>
                </div>

                <div v-if="showSlider" class="price-slider-wrap">
                    <RangeSlider
                        v-model="selected.price"
                        :connect="true"
                        :range="{
                            min: priceLimits[selected.type][0],
                            max: priceLimits[selected.type][1],
                        }"
                        :step="5000"
                        :tooltips="[tooltipFormatter, tooltipFormatter]"
                    />
                </div>
            </div>

            <ShowResultsButton
                prefix="See"
                suffix-singular="Match"
                suffix-plural="Matches"
                :value="filteredCars.length"
                when-zero="No Match"
                :class="{
                    'submit-button': true,
                    disabled: !filteredCars.length,
                }"
                @click="submit"
            />

            <div class="reset-button-wrap">
                <BaseButton type="tertiary" class="reset-button">
                    <button type="button" @click="resetFilters">
                        <BaseIcon
                            :icon-html="History"
                            aria-hidden="true"
                            class="base-icon"
                        />

                        <span>Reset Filters</span>
                    </button>
                </BaseButton>
            </div>
        </div>
    </CenterContent>
</template>

<style lang="postcss" scoped>
.home-search-refine {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 30px;

    & .heading-text {
        flex-shrink: 0;
    }

    & .uppercase {
        & :deep(button) {
            text-transform: uppercase;
        }
    }
}

.filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.select-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 60%;
    flex-grow: 1;
}

select {
    font-family: var(--font-family);
    font-weight: var(--font-weight-normal);
    font-size: 16px;
    color: var(--color-font-dark);
    height: 44px;
    padding: 0 10px;
    padding-right: 1.125rem;
    border: 1px solid var(--color-light-border);
    border-radius: var(--size-input-radius);
    width: 32%;
}

.price-slider-wrap {
    width: 40%;
    padding: 34px 12px 0 32px;
    margin-bottom: 32px;
}

.submit-button {
    /* BaseButton.vue : .base-button first child's direct children starting from the second has a margin-left: 0.8rem  */
    --span-wrap: 0;

    max-width: 480px;
    width: 100%;
    margin: 0 auto 10px;
}

.reset-button-wrap {
    display: flex;
    justify-content: center;

    & .reset-button {
        --span-wrap: 0.25rem;
    }
}

.base-icon {
    --base-icon-size: 1.5rem;
    --base-icon-stroke: none;
}

@media screen and (max-width: 900px) {
    .header-wrap {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
}

@media screen and (max-width: 650px) {
    .select-wrap {
        width: 100%;
    }

    .price-slider-wrap {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (max-width: 440px) {
    .select-wrap {
        flex-direction: column;
    }

    select {
        width: 100%;
        margin-top: 7px;
    }
}
</style>
